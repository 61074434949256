import { ListItem, ListItemIcon, ListItemText, ThemeProvider, useMediaQuery } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import { Notifications, PowerSettingsNew } from "@material-ui/icons"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import { drawerWidth } from "config/basicConfig"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { logout } from "redux/User/actions/UserAuth"
import { getIsUserLoggedIn } from "redux/User/reducer/user"
import Logo from "static/appbar-logo.svg"
import LogoMini from "static/logo192.png"
import { sideBarTheme } from "theme"
import DrawerItem from "./DrawerItem"

const DrawerContainer = ({ open, setOpen }) => {
  const classes = styles(open)
  const dispatch = useDispatch()
  const auth = useSelector(getIsUserLoggedIn)
  const handleDrawerToggle = () => setOpen(x => !x)
  const mobile = useMediaQuery(theme => theme.breakpoints.down("xs"))
  const handleLogout = () => dispatch(logout())
  const location = useLocation()

  if (!auth) return <></>
  return (
    <ThemeProvider theme={sideBarTheme}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !mobile && {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }
            ),
          }}
          variant={mobile ? "temporary" : "permanent"}
          ModalProps={{
            keepMounted: mobile, // Better open performance on mobile.
          }}
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}>
          <Link to={"/"} className={classes.menuButton}>
            <img src={open ? Logo : LogoMini} height={"45px"} alt="Roomlelo" />
          </Link>

          <DrawerItem handleDrawerToggle={handleDrawerToggle} />

          <Box flex={1}></Box>
          <Divider variant="middle" />
          <ListItem button selected={location.pathname === "/alerts"} component={Link} to={"/alerts"}>
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary="Notification" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          <Divider />

          <div className={classes.toolbar} style={{ justifyContent: open ? "flex-end" : "center" }}>
            <IconButton className={classes.arrow} onClick={handleDrawerToggle}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </Drawer>
      </nav>
    </ThemeProvider>
  )
}

export default DrawerContainer

const styles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    overflow: "hidden",
    width: drawerWidth,
    background: "#212b36",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    overflow: "hidden",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    color: "white",
  },
  menuButton: {
    padding: 12,
    display: "flex",
    justifyContent: "center",
  },
}))
