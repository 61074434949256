import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import "ag-grid-community/dist/styles/ag-theme-material.css"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PageLayout from "./Layout/PageLayout"
import { LiveVisitor } from "./redux/User/actions/UserAuth"
import Router from "./routes"

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(LiveVisitor())
    const handleWheel = function (_event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur()
      }
    }
    document.addEventListener("wheel", handleWheel)
    return () => {
      document.removeEventListener("wheel", handleWheel)
    }
  }, [dispatch])

  return (
    <BrowserRouter>
      <PageLayout>
        <Router />
      </PageLayout>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
