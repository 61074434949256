import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseURLv2 } from "config/app"
import qs from "query-string"

// Define a service using a base URL and expected endpoints
export const ApiV2 = createApi({
  reducerPath: "api/v2",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURLv2,

    credentials: "include",
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: "bracket" })
    },
  }),
  endpoints: builder => ({
    getBookings: builder.query({ query: params => ({ url: `${baseURLv2}/reservation`, params }) }),
  }),
})

export const { useGetBookingsQuery } = ApiV2
