export default function Card(theme) {
  return {
    MuiCard: {
      root: {
        boxShadow: theme.customShadows.z8,
        borderRadius: theme.shape.borderRadiusMd,
        position: "relative",
        zIndex: 0, // Fix Safari overflow: hidden with border radius
      },
    },
  }
}
