import { createTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles"
import React from "react"
import ComponentsOverrides from "./overrides"
import themeConfig from "./theme"

const Theme = ({ children }) => {
  const themeOptions = React.useMemo(() => themeConfig, [])

  // const themeOptions = React.useMemo(
  //   () => ({
  //     palette: isLight ? { ...palette.light, mode: "light" } : { ...palette.dark, mode: "dark" },
  //     shape,
  //     typography,
  //     breakpoints,
  //     direction: themeDirection,
  //     shadows: isLight ? shadows.light : shadows.dark,
  //     customShadows: isLight ? customShadows.light : customShadows.dark,
  //   }),
  //   [isLight, themeDirection]
  // )

  const theme = createTheme(themeOptions)
  theme.overrides = ComponentsOverrides(theme)
  theme.props = {
    ...theme.props,
    MuiTablePagination: {
      rowsPerPageOptions: [50, 100, 500, 1000],
    },
  }
  return <ThemeProvider theme={responsiveFontSizes(theme)}>{children}</ThemeProvider>
}

export default Theme

export const sideBarTheme = createTheme({ ...themeConfig, palette: { ...themeConfig.palette, type: "dark" } })
