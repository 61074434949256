import axios from "axios"
import userTypes from "config/userType"
import { equalTo, onValue, orderByChild, query, ref } from "firebase/database"
import { database } from "utils/firebase"
import { AUTH, INACTIVE_VISITOR, LIVE_VISITOR } from "../type"

export const signIn = details => async dispatch => {
  const { data } = await axios.post(`/account/login`, details)

  if (Array.isArray(data.user?.access_type) && data.user.access_type.some(d => Object.values(userTypes).includes(d))) {
    dispatch({ type: AUTH, payload: { auth: true, user: data.user } })
    return data
  }
  return { error: true, message: data.message }
}

export const checkUser = () => async dispatch => {
  try {
    const { data } = await axios.get(`/account/checkAuth`)

    if (Array.isArray(data.user?.access_type) && data.user.access_type.some(d => Object.values(userTypes).includes(d))) {
      dispatch({ type: AUTH, payload: { auth: true, user: data.user } })
    } else {
      dispatch(unAuthUser())
    }
  } catch (error) {
    console.error(error)
  }
}

export const logout = () => dispatch => {
  axios
    .delete(`/account/logout`)
    .then(({ data }) => {
      if (data.success) {
        dispatch(unAuthUser())
      }
    })
    .catch(r => console.log(r))
}
export const unAuthUser = () => dispatch => {
  dispatch({ type: AUTH, payload: { auth: false, user: null } })
}

export const LiveVisitor = () => dispatch => {
  const starCountRef = query(ref(database, "/tempUser/"), orderByChild("state"), equalTo("online"))
  onValue(starCountRef, snapshot => {
    dispatch({ type: LIVE_VISITOR, payload: snapshot.size })
  })
  dispatch(InactiveVisitors())
}
export const InactiveVisitors = () => dispatch => {
  const starCountRef = query(ref(database, "/tempUser/"), orderByChild("state"), equalTo("offline"))
  onValue(starCountRef, snapshot => {
    dispatch({ type: INACTIVE_VISITOR, payload: snapshot.size })
  })
}
