//owner
export const OWNER_REQUESTS = "OWNER_REQUESTS"
export const SET_MOBILE_NUMBER = "SET_ENQUIRY_MOBILE_NUMBER"

//schedule form
export const SET_SCHEDULE_NUMBER = "SET_SCHEDULE_NUMBER"
export const SET_USER_RECORD_MESSAGE = "SET_USER_RECORD_MESSAGE"
export const SET_USER_RECORD_DATA = "SET_USER_RECORD_DATA"

// PRIVATE ROOM
export const SET_ROOMS = "SET_ROOMS"
export const DELETE_ROOMS = "DELETE_ROOMS"
export const DEFAULT_API_ERR = { error: true, message: "Something went wrong. Please try again." }
