import { configureStore } from "@reduxjs/toolkit"
import { Api } from "./apis/services"
import { ApiV2 } from "./apis/services2"
import drawer from "./Drawer/reducer/drawer"
import property from "./Property/reducer/property"
import propertyForm from "./Property/reducer/propertyForm"
import form from "./reduser/formReducers"
import reservationSlice from "./services/reservation"
import user from "./User/reducer/user"

export const store = configureStore({
  reducer: {
    drawer,
    user,
    form,
    property,
    propertyForm,
    reservation: reservationSlice.reducer,
    // Add the generated reducer as a specific top-level slice
    [Api.reducerPath]: Api.reducer,
    [ApiV2.reducerPath]: ApiV2.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(Api.middleware, ApiV2.middleware),
})
export default store
