import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
}
const app = initializeApp(firebaseConfig)

export const database = getDatabase(app)
export default app
