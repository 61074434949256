import { REMOVE_ROOM_CATEGORY, RESET_PROPERTY_FROM, SET_ROOM_CATEGORY } from "../constants"

const initialState = {
  room_categories: [],
}

export default function propertyFormReducer(state = initialState, actions) {
  switch (actions.type) {
    // edit/add room
    case SET_ROOM_CATEGORY: {
      const room_categories = [...state.room_categories]
      room_categories.push(actions.payload)
      return { ...state, room_categories }
    }
    case REMOVE_ROOM_CATEGORY: {
      if (!actions.payload) return { ...state, room_categories: [] }
      const room_categories = [...state.room_categories]
      return { ...state, room_categories: room_categories.filter(d => d?.name !== actions.payload) }
    }
    case RESET_PROPERTY_FROM:
      return initialState

    default:
      return state
  }
}

// Selectors
export const getRoomCategories = state => state.propertyForm.room_categories
export const getRoomCategory = type => state => state.propertyForm.room_categories.find(d => d.room_type === type)
