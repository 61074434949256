import { SET_MOBILE_NUMBER, SET_USER_RECORD_DATA, SET_USER_RECORD_MESSAGE } from "../type"
const initialState = {
  selectedRequest: "schedule",
  mobileNumber: "",
  userData: {
    User: [],
  },
  selectedType: "",
  userId: "",
  record: {},
  recordMessage: "",
}

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_RECORD_MESSAGE:
      return { ...state, recordMessage: action.payload }
    case SET_USER_RECORD_DATA:
      return { ...state, record: action.payload }

    case SET_MOBILE_NUMBER:
      return { ...state, mobileNumber: action.payload }

    default:
      return state
  }
}

export const getFormData = state => state.form
export const getFormMessage = state => state.form.recordMessage
