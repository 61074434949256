import { useSelector } from "react-redux"
import { Redirect, useLocation } from "react-router-dom"
import Loading from "../components/loading"
import { ADMIN } from "../config/userType"
import { getIsUserLoggedIn, getUserAccess } from "../redux/User/reducer/user"

export const AuthGuard = ({ children: Children, allowedRoles = [], ...props }) => {
  const isUserLoggedIn = useSelector(getIsUserLoggedIn)
  const accessType = useSelector(getUserAccess)
  const { pathname } = useLocation()

  if (isUserLoggedIn === null) return <Loading />

  if (isUserLoggedIn)
    if (accessType.includes(ADMIN) || accessType.some(d => allowedRoles.includes(d))) return <Children {...props} />
    else return <Redirect to="/404" />

  return <Redirect to={{ pathname: "/auth/login", state: { referrer: pathname } }} />
}
const authGuard =
  (BaseComponent, allowedRoles = []) =>
  props => {
    const isUserLoggedIn = useSelector(getIsUserLoggedIn)
    const accessType = useSelector(getUserAccess)
    const { pathname } = useLocation()

    if (isUserLoggedIn === null) return <Loading />

    if (isUserLoggedIn)
      if (accessType.includes(ADMIN) || accessType.some(d => allowedRoles.includes(d))) return <BaseComponent {...props} />
      else return <Redirect to="/404" />

    return <Redirect to={{ pathname: "/auth/login", state: { referrer: pathname } }} />
  }

export default authGuard
