import { CLEAR_PROPERTY_STATE, PROPERTIES, PROPERTY_DETAILS, UPDATE_PROPERTY_STATE } from "../constants"

const initialState = {
  details: null,
  list: null,
}

export default function propertyReducer(state = initialState, actions) {
  switch (actions.type) {
    case PROPERTY_DETAILS:
      return { ...state, details: actions.payload }
    case UPDATE_PROPERTY_STATE:
      return { ...state, details: { ...state.details, ...actions.payload } }
    case CLEAR_PROPERTY_STATE:
      return { ...state, details: null }
    case PROPERTIES:
      return { ...state, list: actions.payload }

    default:
      return state
  }
}

// Selectors
export const getProperty = state => state.property.details
export const getProperties = state => state.property.list
