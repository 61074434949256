export default function ToggleButton(theme) {
  return {
    MuiToggleButton: {
      root: {
        "&.Mui-selected": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          boxShadow: theme.customShadows.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.light + "!important",
          },
        },
      },
    },
  }
}
