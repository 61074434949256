import CssBaseline from "@material-ui/core/CssBaseline"
import { LocalizationProvider } from "@material-ui/pickers"
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import App from "./App"
import "./index.css"
import store from "./redux/store"
import { checkUser } from "./redux/User/actions/UserAuth"
import * as serviceWorker from "./serviceWorker"
import ThemeProvider from "./theme"
import "./utils/axios"

store.dispatch(checkUser())

render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <CssBaseline />
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("roomlelo")
)

serviceWorker.unregister()
