import { DRAWER_OPEN } from "../type"

const initialState = {
  open: false,
}

export default function drawerReducer(state = initialState, actions) {
  switch (actions.type) {
    case DRAWER_OPEN:
      return {
        ...state,
        open: !state.open,
      }

    default:
      return state
  }
}

// Selectors
export const getDrawerStatus = state => state.drawer.open
