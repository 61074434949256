// function path(root, sublink) {
//   return `${root}${sublink}`
// }

// const ROOTS_AUTH = "/auth"
const ROOTS_DASHBOARD = "/"

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
}
