import PrimaryAppBar from "components/Header/PrimaryAppBar"
import { useEffect, useLayoutEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import MainPageLayout from "../MainPageLayout"
import Drawer from "./Drawer"

export default function PageLayout(props) {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  useLayoutEffect(() => {
    const getLayoutSettings = localStorage.getItem("settings")
    if (getLayoutSettings) {
      const layoutSettings = JSON.parse(getLayoutSettings)
      if (typeof layoutSettings.drawer === "boolean") setOpen(layoutSettings.drawer)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify({ drawer: open }))
  }, [open])

  if (location.pathname.includes("/auth") || location.pathname === "/404") return props.children
  return (
    <>
      <Drawer {...{ open, setOpen }} />
      <PrimaryAppBar {...{ open, setOpen }} />
      <MainPageLayout open={open} {...props} />
    </>
  )
}
