import cyan from "@material-ui/core/colors/cyan"
import deepOrange from "@material-ui/core/colors/deepOrange"
import shadows, { customShadows } from "./shadows"

const themeConfig = {
  palette: {
    type: "light",
    primary: {
      ...cyan,
    },
    secondary: {
      ...deepOrange,
    },
    background: {
      default: "#f9f9f9",
    },
  },
  props: {
    MuiChip: {
      size: "small",
    },
    MuiButton: {
      color: "primary",
      size: "small",
    },
    MuiTextField: {
      size: "small",
      variant: "outlined",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    button: {
      textTransform: "none",
    },
  },
  lineButton: {
    // color: '#5c5dfa',
    backgroundColor: "#FFF",
    borderRadius: 20,
    border: "1px solid #DADCE0",
    boxShadow: "none",
    // fontSize: '0.775rem',
    transition: ".2s",
    textTransform: "none",
    height: 25,
    color: cyan[500],
    fontSize: "0.85rem",
    "&:hover": {
      backgroundColor: "#E8F0FD",
      border: "1px solid #E8F0FD",
      color: cyan[700],
    },
  },
  card: {
    position: "relative",
    borderRadius: 8,
    boxShadow: "0 1px 7px rgba(0,0,0,.05)",
    marginBottom: 12,
  },
  shadows: shadows.light,
  customShadows: customShadows.light,
}
export default themeConfig
