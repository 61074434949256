import { AUTH, INACTIVE_VISITOR, LIVE_VISITOR } from "../type"

const initialState = {
  auth: null,
  user: null,
  liveVisitor: null,
  inactiveVisitors: null,
}

export default function userReducer(state = initialState, actions) {
  switch (actions.type) {
    case AUTH:
      return {
        ...state,
        auth: actions.payload.auth,
        user: actions.payload.user,
      }

    case LIVE_VISITOR:
      return { ...state, liveVisitor: actions.payload }
    case INACTIVE_VISITOR:
      return { ...state, inactiveVisitors: actions.payload }
    default:
      return state
  }
}

// Selectors
export const getUserAccess = state => state.user.user?.access_type || []
export const getUser = state => state.user.user
export const getIsUserLoggedIn = state => state.user.auth
export const getLiveVisitorCount = state => state.user.liveVisitor
export const getInactiveVisitorCount = state => state.user.inactiveVisitors
