import { createSlice } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"

const initialState = {
  with_token_money: false,
  monthly_rent: "",
  with_custom_monthly_rent: false,
  payment_amount: "",
  payment_mode: "",
  reservation_type: "",
  booking_date: null,
  property_type: "",
  properties_id: "",
  room_id: "",
  bed_id: "",
  move_in_date: "",
  note: "",
}

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setReservationClear: () => initialState,
    setReservation: (state, { payload }) => ({ ...state, ...payload }),
  },
})

// Exporting data for selectors
export const getReservationState = state => state.reservation

export default reservationSlice

export function useGetReservation() {
  const dispatch = useDispatch()
  const data = useSelector(getReservationState)
  const setReservationData = data => {
    dispatch(reservationSlice.actions.setReservation(data))
  }
  const reset = () => dispatch(reservationSlice.actions.setReservationClear())
  // return the import data for the caller of the hook to use
  return { setReservationData, reset, ...data }
}
