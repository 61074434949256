import { Collapse, Grid, LinearProgress, List, ListItemIcon, ListItemText, makeStyles, useMediaQuery } from "@material-ui/core"
import {
  AddLocation,
  BarChart,
  ContactSupport,
  DialerSip,
  ExpandLess,
  ExpandMore,
  HeadsetMicOutlined,
  HourglassEmpty,
  HowToReg,
  ImportExport,
  NotListedLocation,
  People,
  PersonPinCircle,
} from "@material-ui/icons"
import HomeIcon from "@material-ui/icons/Home"
import HomeWorkIcon from "@material-ui/icons/HomeWork"
import ReceiptIcon from "@material-ui/icons/Receipt"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import { ACCOUNT_MANAGER, ADMIN, CUSTOMER_MANAGER, PROPERTY_MANAGER, SERVICE_MANAGER } from "config/userType"
import { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getUserAccess } from "redux/User/reducer/user"
import { ListItem } from "./DrawerListItemComponent"

export default function DrawerItem({ handleDrawerToggle }) {
  const classes = useStyles()
  const location = useLocation()
  const [collapse, setCollapse] = useState({})
  const mobile = useMediaQuery(theme => theme.breakpoints.down("xs"))
  const accessType = useSelector(getUserAccess)
  const handleDrawerInMobile = () => {
    if (mobile) handleDrawerToggle()
  }
  const haveAccess = ({ path, access, child }) => {
    const isAdmin = accessType?.includes(ADMIN)
    if (child) {
      if (isAdmin) return child
      const items = child.filter(item => haveAccess(item))
      return items
    }
    if (isAdmin) return true
    if (accessType.some(d => access?.includes(d))) return true
  }
  if (!accessType) return <LinearProgress />
  return (
    <Grid item className={classes.drawerItem}>
      <List component="nav" dense>
        {items.map(item => {
          const { path, name, icon, child } = item,
            accessGranted = haveAccess(item)

          if (!(child || path === "/" || accessGranted)) return ""
          if (child) {
            if (!accessGranted?.length) return ""
            return (
              <Fragment key={name}>
                <ListItem button onClick={() => setCollapse({ [path]: !collapse[path] })}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                  {collapse[path] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={collapse[path]} timeout="auto" unmountOnExit>
                  <List component="nav" dense disablePadding className={classes.subList}>
                    {accessGranted.map(d => (
                      <ListItem key={d.name} selected={location.pathname === d.path} button component={Link} to={d.path} onClick={handleDrawerInMobile}>
                        <ListItemIcon color="inherit">{d.icon}</ListItemIcon>
                        <ListItemText primary={d.name} primaryTypographyProps={{ noWrap: true }} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            )
          }
          return (
            <Fragment key={name}>
              <ListItem selected={location.pathname === path} button component={Link} to={path} onClick={handleDrawerInMobile}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            </Fragment>
          )
        })}
      </List>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  drawerItem: {
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  listSubheader: {
    paddingTop: 12,
    color: "#777",
  },
  subList: {
    "&> *": {
      paddingTop: `4px !important`,
      paddingBottom: `4px !important`,
      background: "#171d23",
    },
  },
}))

const items = [
  { name: "Dashboard", path: "/", icon: <HomeIcon /> },
  { name: "Properties", path: "/property", icon: <HomeWorkIcon />, access: [CUSTOMER_MANAGER, PROPERTY_MANAGER] },
  { name: "Reservation", path: "/reservation", icon: <HeadsetMicOutlined />, access: [CUSTOMER_MANAGER, PROPERTY_MANAGER] },

  { name: "Tenant List", path: "/user/tenant", icon: <HowToReg />, access: [ACCOUNT_MANAGER, PROPERTY_MANAGER] },
  { name: "Invoice", path: "/reservation/invoices", icon: <ReceiptIcon />, access: [ACCOUNT_MANAGER] },
  { name: "Payment", path: "/reservation/payments", icon: <ImportExport />, access: [ACCOUNT_MANAGER] },

  { name: "Tenant Support", path: "/support/tenant", icon: <ContactSupport />, access: [SERVICE_MANAGER] },
  {
    name: "Users",
    path: "user",
    icon: <People />,
    child: [
      { name: "Clients", path: "/user", icon: <People fontSize="small" /> },
      { name: "Owners", path: "/user/owner", icon: <People fontSize="small" /> },
      { name: "Managements", path: "/user/managements", icon: <SupervisorAccountIcon fontSize="small" /> },
    ],
  },
  {
    name: "Records",
    path: "record",
    icon: <BarChart />,
    child: [
      { name: "Enquiry List", path: "/reservation/enquiry", icon: <NotListedLocation fontSize="small" />, access: [CUSTOMER_MANAGER] },
      { name: "Visit List", path: "/reservation/visits", icon: <PersonPinCircle fontSize="small" />, access: [PROPERTY_MANAGER] },
      { name: "Booking List", path: "/reservation/bookings", icon: <PersonPinCircle fontSize="small" />, access: [PROPERTY_MANAGER] },
      { name: "Tenant Waiting List", path: "/property/user/waiting", icon: <HourglassEmpty fontSize="small" /> },
      { name: "IVR Call Record", path: "/ivr", icon: <DialerSip fontSize="small" />, access: [CUSTOMER_MANAGER] },
      { name: "Owner Request", path: "/property/request", icon: <AddLocation fontSize="small" />, access: [PROPERTY_MANAGER, CUSTOMER_MANAGER] },
    ],
  },
]
