export default function CssBaseline(theme) {
  return {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: 8,
          height: 8,
          background: "#fff",
        },

        "*::-webkit-scrollbar-track": {
          background: "#6161613d",
        },

        "*::-webkit-scrollbar-thumb": {
          "-webkitBoxShadow": "inset 0 0 6px rgba(0,0,0,0.3)",
          background: "#525252",
        },
      },
    },
  }
}
