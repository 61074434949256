import { merge } from "lodash"
import Button from "./Button"
import Card from "./Card"
import CssBaseline from "./CssBaseline"
import Tabs from "./Tabs"
import ToggleButton from "./ToggleButton"

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return { ...theme.overrides, ...merge(Tabs(theme), CssBaseline(theme), Button(theme), ToggleButton(theme), Card(theme)) }
}
