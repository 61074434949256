import { alpha, Divider as MuiDivider, ListItem as MuiListItem } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

export const ListItem = withStyles(theme => ({
  root: {
    color: "#fff",
    paddingTop: 12,
    paddingBottom: 12,
    "&:hover": {
      background: alpha(theme.palette.common.white, theme.palette.action.selectedOpacity),
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.common.white,
    },
  },

  selected: {
    "&$selected": {
      background: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
    "&::before": {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: "block",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(MuiListItem)

export const Divider = withStyles({
  root: {
    backgroundColor: "rgb(255 255 255 / 12%)",
  },
})(MuiDivider)
