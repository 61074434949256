import Container from "@material-ui/core/Container"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"
import React from "react"
import { drawerWidth } from "../config/basicConfig"

export default function MainPageLayout({ open, ...props }) {
  const classes = styles(open)

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
        // [classes.noMargin]: location.pathname === "/login",
      })}>
      <Container maxWidth={false} {...props} />
    </main>
  )
}

const styles = makeStyles(theme => ({
  content: {
    flexDirection: "column",
    display: "flex",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(7) + 1,
    },
  },
  contentShift: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  },
  noMargin: {
    marginLeft: 0,
  },
}))
