// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTab: {
      root: {
        padding: 0,
        fontWeight: theme.typography.fontWeightMedium,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        "&.Mui-selected": {
          color: theme.palette.text.primary,
        },
        "&:not(:last-of-type)": {
          marginRight: theme.spacing(5),
        },
        "@media (min-width: 600px)": {
          minWidth: 48,
        },
      },
      labelIcon: {
        minHeight: 48,
        paddingTop: 0,
        flexDirection: "row",
      },
      wrapper: {
        flexDirection: "row",
        whiteSpace: "nowrap",

        "& > *:first-of-type": {
          marginBottom: `0 !important`,
          marginRight: theme.spacing(1),
        },
      },
      textColorInherit: {
        opacity: 1,
        color: theme.palette.text.secondary,
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiTabScrollButton: {
      root: {
        width: 48,
        borderRadius: "50%",
      },
    },
  }
}
