import { alpha, useMediaQuery } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import MenuIcon from "@material-ui/icons/Menu"
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew"
import clsx from "clsx"
import { drawerWidth } from "config/basicConfig"
import { cloneElement } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "redux/User/actions/UserAuth"
import { getIsUserLoggedIn } from "redux/User/reducer/user"

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

function PrimaryAppBar({ children, open, setOpen, ...props }) {
  const auth = useSelector(getIsUserLoggedIn)

  const classes = useStyles()
  const dispatch = useDispatch()
  const handleLogout = () => dispatch(logout())
  const handleDrawerToggle = () => setOpen(x => !x)
  const mobile = useMediaQuery(theme => theme.breakpoints.down("xs"))

  if (!mobile) return <></>
  return (
    <div className={classes.root}>
      <ElevationScroll {...props}>
        <AppBar
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}>
          <Toolbar variant="dense">
            <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} className={classes.menuButtonL}>
              <MenuIcon />
            </IconButton>

            <div className={classes.appBarContainer}>
              <div className={classes.grow} />
              {auth && mobile && (
                <IconButton onClick={handleLogout}>
                  <PowerSettingsNewIcon />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar variant="dense" />
    </div>
  )
}

export default PrimaryAppBar

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      marginLeft: theme.spacing(7) + 1,
    },
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },

  grow: {
    flexGrow: 1,
  },
  menuButtonL: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  appBarContainer: {
    paddingLeft: 10,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}))
