import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { getIsUserLoggedIn } from "../redux/User/reducer/user"
import { PATH_DASHBOARD } from "../routes/paths"

const guestGuard = BaseComponent => props => {
  const isUserLoggedIn = useSelector(getIsUserLoggedIn)

  if (isUserLoggedIn) return <Redirect to={props.location.state?.referrer || PATH_DASHBOARD.root} />

  return <BaseComponent {...props} />
}

export default guestGuard
